export function Error404() {
    return(
        <>
        <div className="utility-page-wrap">
            <div className="utility-page-content"><img src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg" alt=""></img>
            <h2>Page Not Found</h2>
            <div className="error-description">The page you are looking for doesn't exist or has been moved</div>
            </div>
        </div>
        </>
    )
}