import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home.js';
import { Error404 } from './pages/Error404.js';
import { Navbar } from './components/Navbar.js';
import './css/index.css';
import { Imprint } from './pages/Imprint.js';
import LanguageProvider from './LanguageProvider.js';

function App() {
  return (
    <>
    <LanguageProvider>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/imprint" element={<Imprint/>}/>
        <Route path="*" element={<Error404/>}/>
      </Routes>
    </LanguageProvider>
    </>
  );
}

export default App;