import logo from '../assets/img/valolytics.svg';
import premierLogo from '../assets/img/premier.png';
import { FaXTwitter, FaDiscord, FaPatreon } from "react-icons/fa6";
import PremierBackground from '../assets/img/premier_background.png';
import LftLogo from '../assets/img/lft.png';
import LftBackground from '../assets/img/lft_background.png';
import HeatmapShowcase from '../assets/img/heatmap_showcase.png';
import MatchShowcase from '../assets/img/match_showcase.png';
import TeamstatsShowcase from '../assets/img/teamstats_showcase.png';
import LftPostShowcase from '../assets/img/lftpost_showcase.png';
import PlayerstatsShowcase from '../assets/img/playerstats_showcase.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import translations from '../assets/translations.js';
import { LanguageContext } from '../LanguageProvider.js';
import { useContext } from 'react';

import '../css/premier-spotlight.css';
import '../css/lft-spotlight.css';
import '../css/bot-spotlight.css';
import '../css/footer.css';

export function Home() {
    const { language } = useContext(LanguageContext);

    return(
        <div className="main">
            <div className="premier-spotlight" style={{backgroundImage: `url(${PremierBackground})`}}>
                <img src={premierLogo} alt="Premier Logo"/>
                <h1 data-text={translations[language].premierSpotlight.title}>{translations[language].premierSpotlight.title}</h1>
                <p>{translations[language].premierSpotlight.text}</p>
                <a className="premier-spotlight-button" href="https://stats.valolytics.gg">{translations[language].premierSpotlight.button}</a>
            </div>
            <div className="bot-spotlight">
                <div className="bot-spotlight container">
                    <div className="bot-spotlight-info">
                        <h1 className="fade">{translations[language].discordBotSpotlight.title}<br/></h1>
                        <p className="paragraph-2">{translations[language].discordBotSpotlight.text}</p>
                        <a className="bot-spotlight-button" href="https://discord.com/api/oauth2/authorize?client_id=942068502645317723&amp;permissions=2147805184&amp;scope=bot%20applications.commands"><FaDiscord style={{marginRight: '10px'}} />{translations[language].discordBotSpotlight.button}</a>
                    </div>
                    <Carousel className="bot-spotlight carousel" centerMode={false} swipeable={false} autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={5000}>
                        <div>
                            <img src={HeatmapShowcase} alt="Heatmap Command Showcase"/>
                            <p className="legend">{translations[language].discordBotSpotlight.carousel.heatmap.info}<br/>{translations[language].discordBotSpotlight.carousel.heatmap.howToUse}</p>
                        </div>
                        <div>
                            <img src={LftPostShowcase} alt="Lft Post Command Showcase"/>
                            <p className="legend">{translations[language].discordBotSpotlight.carousel.lftPost.info}<br/>{translations[language].discordBotSpotlight.carousel.lftPost.howToUse}</p>
                        </div>
                        <div>
                            <img src={PlayerstatsShowcase} alt="Player Stats Command Showcase"/>
                            <p className="legend">{translations[language].discordBotSpotlight.carousel.playerStats.info}<br/>{translations[language].discordBotSpotlight.carousel.playerStats.howToUse}</p>
                        </div>
                        <div>
                            <img src={TeamstatsShowcase} alt="Team Stats Command Showcase"/>
                            <p className="legend">{translations[language].discordBotSpotlight.carousel.teamStats.info}<br/>{translations[language].discordBotSpotlight.carousel.teamStats.howToUse}</p>
                        </div>
                        <div>
                            <img src={MatchShowcase} alt="Match Command Showcase"/>
                            <p className="legend">{translations[language].discordBotSpotlight.carousel.match.info}<br/>{translations[language].discordBotSpotlight.carousel.match.howToUse}</p>
                        </div>
                    </Carousel>
                </div>
            </div>
            <div className="lft-spotlight">
                <div className="background" style={{backgroundImage: `url(${LftBackground})`}}/>
                <div className="lft-spotlight-info">
                    <h1 data-text={translations[language].lftValorantEmeaDiscordSpotlight.title}>{translations[language].lftValorantEmeaDiscordSpotlight.title}</h1>
                    <p>{translations[language].lftValorantEmeaDiscordSpotlight.text}</p>
                    <a className="lft-spotlight-button" href="https://discord.gg/GfCU3xxnYb"><FaDiscord color="#0f0f0f" style={{marginRight: '10px'}} />{translations[language].lftValorantEmeaDiscordSpotlight.button}</a>
                </div>
                <img src={LftLogo} alt="LFT EMEA Logo"/>
            </div>
            <div className="footer">
                <img src={logo} loading="lazy" width="118" alt="" className="image-6"/>
                <div className="footer-links">
                    <a href="imprint" className="link-block tosnpp">{translations[language].footer.imprint}</a>
                    <a href="mailto:info@valolytics.gg" className="link-block tosnpp">{translations[language].footer.contact}</a>
                    <a href="https://www.privacypolicyonline.com/live.php?token=2RkofU6MB6pfIIezG4WL7Pv8L0Jov2wq" target="_blank" rel="noreferrer" className="link-block tosnpp">{translations[language].footer.tos}</a>
                    <a href="https://www.privacypolicyonline.com/live.php?token=CTJ2vwJrQX7JdigayqMGT7Xodp6cHS9Q" target="_blank" rel="noreferrer" className="link-block tosnpp">{translations[language].footer.pp}</a>
                </div>
                <div className="footer-socials">
                    <a href="https://twitter.com/valolytics_gg" target="_blank" rel="noreferrer" className="social">
                        <FaXTwitter size="30" color="white" />
                    </a>
                    <a href="https://discord.gg/kgYhPXzvEX" target="_blank" rel="noreferrer" className="social">
                        <FaDiscord size="35" color="white" />
                    </a>
                    <a href="https://www.patreon.com/valolytics" target="_blank" rel="noreferrer" className="social">
                        <FaPatreon size="25" color="white" />
                    </a>
                </div>
            </div>
        </div>
    )
}